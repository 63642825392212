import { useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import HeaderWithoutSidebar from "#components/common/HeaderWithoutSidebar";
import LoginBox from "#components/login/LoginBox";
import { Link } from "react-router-dom";
import qs from "qs";
import { useQuery } from "#hooks/useQuery";
import { SAVE_INTEGRATION_CREDS } from "../mutations";
import { AppStateContext } from "#contexts/appState";
import Cookies from "universal-cookie";

/**
 * Redirects the user to the integrations page with the specified response and tenant information.
 *
 * @param {Object} res - The response object to be included in the redirect URL.
 */
const redirect = (res) => {
  const cookies = new Cookies();
  const tenantInfo = cookies.get("hsTenantInfo");

  /**
   * Decodes the "hsTenantInfo" cookie and constructs the redirect URL.
   */
  if (tenantInfo) {
    const state = decodeURIComponent(tenantInfo).split("|");
    const url = `${state[1]}/integrations/?res=${JSON.stringify(
      res
    )}&state=${encodeURI(tenantInfo)}`;
    window.location.href = url;
  }
};

const Login = (props) => {
  const appState = useContext(AppStateContext);
  const res = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const urlParams = new URLSearchParams(window.location.search);
  const { marketplace, action } = useParams();
  const saveIntegrationCredsQuery = useQuery(
    SAVE_INTEGRATION_CREDS,
    res.state?.split("|")[0]
  );

  useEffect(() => {
    if (res.code) {
      redirect(res);
    }
    if (action === "accept") {
      if (res.spapi_oauth_code) {
        // AMAZON CALLBACK
        redirect(res);
      } else {
        const state = res.state?.split("|");
        saveIntegrationCredsQuery.fetchData({
          tenant: state[2],
          customer: state[3],
          integrationType: "eBay",
          clientId: "Hopstack-Hopstack-PRD-b0905fb5f-3697805e",
          clientSecret: "PRD-0905fb5f60be-fcc7-41c3-b939-ada4",
          authCode: res.code,
          redirectUri: "Hopstack_Inc-Hopstack-Hopsta-bskaripbh",
          warehouse: state[4],
        });
      }
    }
    if (!action && res.amazon_callback_uri) {
      const authorizedUrl = `${res.amazon_callback_uri}?amazon_state=${res.amazon_state}&selling_partner_id=${res.selling_partner_id}`;
      window.location.href = authorizedUrl;
    }
  }, []);

  useEffect(() => {
    if (saveIntegrationCredsQuery.data) {
      appState.setAlert(
        saveIntegrationCredsQuery.data.saveIntegrationCreds.message,
        "success",
        5000
      );
    }

    if (saveIntegrationCredsQuery.error) {
      if (saveIntegrationCredsQuery.error.message.indexOf("Request") === -1) {
        appState.setAlert(
          saveIntegrationCredsQuery.error.message,
          "error",
          5000
        );
      } else {
        appState.setAlert(
          `Something went wrong. Please try again later.`,
          "error",
          5000
        );
      }
    }

    if (saveIntegrationCredsQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    saveIntegrationCredsQuery.error,
    saveIntegrationCredsQuery.loading,
    saveIntegrationCredsQuery.data,
  ]);

  return (
    <>
      <div className="h-screen w-full bg-224E73 flex items-center justify-center">
        {/* Header */}
        <HeaderWithoutSidebar />
        <LoginBox subdomain={res.state?.split("|")[1]} action={action} />
        {/*Center Box*/}
      </div>
      <footer className="-mt-8 w-full items-center justify-center flex text-white space-x-5">
        <div>
          Copyright ©
          <a href="http://hopstack.io" className="px-2" target="_blank">
            Hopstack Inc.
          </a>
          {new Date().getFullYear()}
        </div>

        <div>
          <Link target="_blank" to="/privacyPolicy">
            Privacy Policy
          </Link>
        </div>
        <div>
          <Link target="_blank" to="/termsOfUse">
            Terms of Use
          </Link>
        </div>
        <div>
          <Link target="_blank" to="/disclaimer">
            Disclaimer
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Login;
