import "#styles/globals.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "#config/routes";
import Layout from "#components/common/Layout";
import { StatDashboardProvider } from "#contexts/statsDashboard";

import ErrorBoundary from "#components/common/ErrorBoundary";
import { EntityProvider } from "#contexts/entity";

const App = () => {
  return (
    <ErrorBoundary>
      <EntityProvider>
        <StatDashboardProvider>
          <Layout>
            <Router>
              <Switch>
                <Route component={Routes} />
              </Switch>
            </Router>
          </Layout>
        </StatDashboardProvider>
      </EntityProvider>
    </ErrorBoundary>
  );
};

export default App;
