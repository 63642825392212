import { gql } from "@apollo/client";

export const LOGOUT_USER = `
  mutation Logout {
    logout {
      message
    }
  }
`;

export const SET_ACTIVITY = `
  mutation setActivity($activity: String) {
    setActivity(activity: $activity) {
      message
    }
  }
`;

export const SET_STATION = `
  mutation setStation($station: String, $logoutAll: Boolean) {
    setStation(station: $station, logoutAll: $logoutAll) {
      message
    }
  }
`;

export const SAVE_USER = `
  mutation saveUser($name: String!, $email: String, $role: String!, $hopstackModules: [String!], $id: ID, $password: String!, $customers: [ID!], $warehouses: [ID!], $permissions: [UserPermissionInput!], $pagePreferences: [UserPagePreferenceInput!], $activated: Boolean, $suspended: Boolean) {
    saveUser(
      userInput: { name: $name, email: $email, role: $role, hopstackModules: $hopstackModules, id: $id, password: $password, customers: $customers, warehouses: $warehouses, permissions: $permissions, pagePreferences: $pagePreferences, activated: $activated, suspended: $suspended }
    ) {
      message
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword($token: String!, $password: String!) {
    setPassword(token: $token, password: $password) {
      message
    }
  }
`;

export const RESET_PASSWORD = `
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      message
    }
  }
`;

export const DELETE_USER = `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      message
    }
  }
`;

export const SAVE_STATION = `
  mutation saveStation(
    $name: String
    $hopstackModule: String
    $id: ID
    $devices: [ID!]
    $customers: [ID!]
    $warehouses: [ID!]
  ) {
    saveStation(
      stationInput: {
        name: $name
        hopstackModule: $hopstackModule
        id: $id
        devices: $devices
        customers: $customers
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_STATION = `
  mutation deleteStation($id: ID!) {
    deleteStation(id: $id) {
      message
    }
  }
`;

export const SAVE_WORKFLOW = `
  mutation saveWorkflow(
    $workflowType: String!
    $id: ID
    $customers: [ID!]
    $warehouses: [ID!]
    $stages: [WorkflowStationInput!]
    $pickingStrategy: String
    $compliance: String
    $minBatchSize: Int
    $maxBatchSize: Int
    $waveSize: Int
  ) {
    saveWorkflow(
      workflowInput: {
        workflowType: $workflowType
        id: $id
        customers: $customers
        stages: $stages
        warehouses: $warehouses
        pickingStrategy: $pickingStrategy
        compliance: $compliance
        minBatchSize: $minBatchSize
        maxBatchSize: $maxBatchSize
        waveSize: $waveSize
      }
    ) {
      message
    }
  }
`;

export const ADD_TO_WORKFLOW = `
  mutation addToWorkflow(
    $name: String!
    $id: ID
    $actions: [AnyAttributes]
    $displayFields: [AnyAttributes]
    $stageId: ID
    $enabled: Boolean
    $customer: ID
    $warehouse: ID
    $workflowType: String
    $positiveActions: [AnyAttributes]
    $negativeActions: [AnyAttributes]
    $lpnEnabled: Boolean
  ) {
    addToWorkflow(
      addWorkflowInput: {
        name: $name
        id: $id
        actions: $actions
        displayFields: $displayFields
        stageId: $stageId
        enabled: $enabled
        customer: $customer
        warehouse: $warehouse
        workflowType: $workflowType
        positiveActions: $positiveActions
        negativeActions: $negativeActions
        lpnEnabled: $lpnEnabled
      }
    ) {
      message
    }
  }
`;

export const DELETE_WORKFLOW = `
  mutation deleteWorkflow($id: ID!) {
    deleteWorkflow(id: $id) {
      message
    }
  }
`;

export const SAVE_INTEGRATION = `
  mutation SaveIntegration(
    $username: String!
    $password: String!
    $storeName: String!
    $integrationType: String!
    $locationId: String
    $customer: ID
    $warehouse: ID
    $id: ID
  ) {
    saveIntegration(
      integrationInput: {
        username: $username
        password: $password
        id: $id
        customer: $customer
        warehouse: $warehouse
        integrationType: $integrationType
        storeName: $storeName
        locationId: $locationId
      }
    ) {
      message
    }
  }
`;

export const DELETE_INTEGRATION = `
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      message
    }
  }
`;

export const SAVE_DEVICE = `
  mutation saveDevice(
    $name: String!
    $id: ID
    $hardwareId: String!
    $deviceType: String!
    $customers: [ID!]
    $warehouses: [ID!]
  ) {
    saveDevice(
      deviceInput: {
        name: $name
        id: $id
        hardwareId: $hardwareId
        deviceType: $deviceType
        customers: $customers
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_DEVICE = `
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      message
    }
  }
`;

export const SAVE_WAREHOUSE = `
  mutation saveWarehouse(
    $name: String
    $id: ID
    $code: String
    $location: String
    $typeOfWarehouse: [String!]
    $splitOrdersEnabled: Boolean
    $active: Boolean
    $storageTypes: [String]
  ) {
    saveWarehouse(
      warehouseInput: {
        name: $name
        id: $id
        code: $code
        location: $location
        typeOfWarehouse: $typeOfWarehouse
        splitOrdersEnabled: $splitOrdersEnabled
        active: $active
        storageTypes: $storageTypes
      }
    ) {
      message
    }
  }
`;

export const DELETE_WAREHOUSE = `
  mutation deleteWarehouse($id: ID!) {
    deleteWarehouse(id: $id) {
      message
    }
  }
`;

export const SAVE_SKU_BUNDLE = `
  mutation saveSkuBundle(
    $id: ID
    $name: String!
    $sku: String!
    $enabled: Boolean!
    $bundle: [SkuBundleContentItemInput!]
    ) {
    saveSkuBundle(skuBundleInput: {
      id: $id
      name: $name
      sku: $sku
      enabled: $enabled
      bundle: $bundle
    }) {
      message
    } 
  }
`;

export const DELETE_SKU_BUNDLE = `
  mutation deleteSkuBundle($id: ID!) {
    deleteSkuBundle(id: $id) {
      message
    } 
  }
`;

export const SAVE_CUSTOMER = `
  mutation saveDevice(
    $name: String
    $id: ID
    $code: String
    $warehouses: [ID!]
    $currency: String
    $active: Boolean
  ) {
    saveCustomer(
      customerInput: {
        name: $name
        id: $id
        code: $code
        warehouses: $warehouses
        currency: $currency
        active: $active
      }
    ) {
      message
    }
  }
`;

export const DELETE_CUSTOMER = `
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      message
    }
  }
`;

export const SAVE_GROUP = `
  mutation saveGroup(
    $name: String!
    $id: ID
    $mainCriteria: String!
    $conditions: [ConditionInput!]!
  ) {
    saveGroup(
      groupInput: {
        name: $name
        id: $id
        mainCriteria: $mainCriteria
        conditions: $conditions
      }
    ) {
      message
    }
  }
`;

export const DELETE_GROUP = `
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      message
    }
  }
`;
// PICKER

export const SCAN_PICKER_BARCODE = `
    mutation ScanPickerBarcode($code: String!) {
    scanPickerBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_PICK_ITEM = `
  mutation ConfirmPickItem($id: ID!, $item: PickerConfirmItem!) {
    confirmPickItem(id: $id, item: $item) {
      message
    }
  }
`;

export const CONFIRM_PICKER_DROPOFF = `
  mutation ConfirmPickerDropoff($id: ID!) {
    confirmPickerDropoff(id: $id) {
      message
    }
  }
`;

// BATCH

export const TRY_RESOLVE_EXCEPTION = `
  mutation TryResolve($id: ID!) {
    tryResolve(id: $id) {
      message
    }
  }
`;

export const CREATE_MANUAL_ORDER_BATCH = `
  mutation CreateManualOrderBatch($ids: [ID!], $user: ID) {
    createManualOrderBatch(ids: $ids, user: $user) {
      message
    }
  }
`;

// export const CREATE_AUTO_ORDER_BATCHES = `
//   mutation CreateAutoOrderBatches($startDate: Date, $endDate: Date) {
//     createAutoOrderBatches(startDate: $startDate, endDate: $endDate) {
//       message
//     }
//   }
// `;

export const CREATE_AUTO_ORDER_BATCHES = `
  mutation CreateAutoOrderBatches {
    createAutoOrderBatches {
      message
    }
  }
`;

export const SIMULATE_AUTO_ORDER_BATCHES = `
  mutation SimulateAutoOrderBatches {
    simulateAutoOrderBatches {
      items {
        uniqueIdentifier
        binLocation
        sku
        quantity
        reference
        price
        attributes {
          color
          collection
          size
        }
      }
      rows
    }
  }
`;

export const UPDATE_ORDER_TRACKING_INFO = `
  mutation UpdateTrackingInfo($orderInfo: Random) {
    updateTrackingInfo(orderInfo: $orderInfo) {
      message
    }
  }
`;

// PACKER

export const SCAN_PACKER_BARCODE = `
mutation ScanPackerBarcode($code: String!) {
  scanPackerBarcode(code: $code) {
    message
  }
}
`;

export const SET_PACKER_BOXES = `
  mutation SetPackerBoxes($id: ID!, $boxes: [BoxTypeInput]) {
    setPackerBoxes(id: $id, boxes: $boxes) {
      message
    }
  }
`;

export const CONFIRM_PACK_ITEM = `
  mutation ConfirmPackItem($id: ID!, $status: String!, $itemId: String, $boxName: String, $weight: Float, $quantity: Int) {
    confirmPackItem(id: $id, status: $status, itemId: $itemId, boxName: $boxName, weight: $weight, quantity: $quantity) {
      message
    }
  }
`;

export const CONFIRM_TRANSPORT_INFORMATION = `
  mutation ConfirmTransportInformation($id: ID!) {
    confirmTransportInformation(id: $id) {
      message
    }
  }
`;

export const CANCEL_PACK_ITEM = `
  mutation CancelPackItem($id: ID!) {
    cancelPackItem(id: $id) {
      message
    }
  }
`;

export const RESTART_PACK = `
  mutation RestartPack($id: ID!) {
    restartPack(id: $id) {
      message
    }
  }
`;

export const CONFIRM_PACKER_DROPOFF = `
  mutation ConfirmPackerDropoff($id: ID!) {
    confirmPackerDropoff(id: $id) {
      message
    }
  }
`;

export const SAVE_SKU_BIN_MAPPING = `
  mutation saveSkuBinMapping(
    $sku: String!
    $id: ID
    $binLocation: String!
    $customers: [ID!]
    $warehouses: [ID!]
    $quantity: Int
    $palletId: String
    $unallocatedQuantity: Int
    $allocatedQuantity: Int
    $customer: ID
    $warehouse: ID
  ) {
    saveSkuBinMapping(
      skuBinMappingInput: {
        sku: $sku
        binLocation: $binLocation
        id: $id
        warehouses: $warehouses
        customers: $customers
        quantity: $quantity
        palletId: $palletId
        unallocatedQuantity: $unallocatedQuantity
        allocatedQuantity: $allocatedQuantity
        customer: $customer
        warehouse: $warehouse
      }
    ) {
      message
    }
  }
`;

export const SAVE_SKU_BIN_MAPPINGS = `
  mutation saveSkuBinMappings(
    $skuBinMappingsInput: [SkuBinMappingInput]
  ) {
    saveSkuBinMappings(
      skuBinMappingsInput: $skuBinMappingsInput
    ) {
      message
    }
  }
`;

export const DELETE_SKU_BIN_MAPPING = `
  mutation deleteSkuBinMapping($id: ID!) {
    deleteSkuBinMapping(id: $id) {
      message
    }
  }
`;

export const SAVE_STANDALONE_SKU = `
  mutation SaveStandaloneSku($sku: String!) {
    saveStandaloneSku(sku: $sku) {
      message
    }
  }
`;

export const SAVE_STANDALONE_BIN_LOCATION = `
  mutation SaveStandaloneBinLocation($binLocation: String!) {
    saveStandaloneBinLocation(binLocation: $binLocation) {
      message
    }
  }
`;

export const RUN_COMBINED_ORDERS = `
  mutation RunCombinedOrders {
    runCombinedOrders {
      _id {
        name
        city
        state
        street
      }
      Counter
    }
  }
`;

export const MANUALLY_MARK_ORDERS = `
  mutation ManuallyMarkOrders($ids: [ID!], $status: String!) {
    manuallyMarkOrders(ids: $ids, status: $status) {
        message
    }
  }
`;

// RECEIVER

export const CONFIRM_RECEIVER_ITEM = `
  mutation ConfirmReceiverItem($id: ID!, $status: String!) {
    confirmReceiverItem(id: $id, status: $status) {
      message
    }
  }
  `;

export const START_RECEIVING = `
  mutation StartReceiving($consignmentId: ID!) {
    startReceiving(consignmentId: $consignmentId) {
      message
    }
  }
`;

// QUALITY CHECKING

export const CONFIRM_QUALITY_CHECKING_ITEM = `
  mutation confirmQualityCheckingItem($id: ID!, $status: String!) {
    confirmQualityCheckingItem(id: $id, status: $status) {
      message
    }
  }
`;

// PUTAWAY

export const CONFIRM_PUTAWAY_ITEM = `
  mutation confirmPutawayItem($id: ID!) {
    confirmPutawayItem(id: $id) {
      message
    }
  }
`;

export const SAVE_PRODUCT = `
  mutation saveProduct(
    $name: String
    $asin: String
    $upc: [String]
    $ean: [String]
    $sku: String
    $fnSku: String
    $customer: ID
    $warehouse: ID
    $id: ID
    $source: String
    $partOfBundle: Boolean
    $sellerSku: String
    $image: String
    $lpn: [String]
    $attributes: Random
    $active: Boolean
  ) {
    saveProduct(
      productInput: {
        name: $name
        asin: $asin
        upc: $upc
        ean: $ean
        sku: $sku
        fnSku: $fnSku
        warehouse: $warehouse
        customer: $customer
        id: $id
        source: $source
        partOfBundle: $partOfBundle
        sellerSku: $sellerSku
        image: $image
        lpn: $lpn
        attributes: $attributes
        active: $active
      }
    ) {
      message
    }
  }
`;

export const MERGE_PRODUCT = `
  mutation MergeProduct(
    $sourceProductId: ID
    $destinationProductId: ID
  ) {
    mergeProduct(sourceProductId: $sourceProductId, destinationProductId: $destinationProductId) {
      message
    }
  }
`;

export const DELETE_PRODUCT = `
  mutation deleteProduct($id: ID) {
    deleteProduct(id: $id) {
      message
    }
  }
`;

export const SAVE_TOTE = `
  mutation saveTote(
    $barcode: String
    $toteType: String
    $id: ID
    $customers: [ID!]
    $warehouses: [ID!]
    $subTotes: [String!]
  ) {
    saveTote(
      toteInput: {
        barcode: $barcode
        id: $id
        toteType: $toteType
        customers: $customers
        warehouses: $warehouses
        subTotes: $subTotes
      }
    ) {
      message
    }
  }
`;

export const DELETE_TOTE = `
  mutation DeleteTote($id: ID!) {
    deleteTote(id: $id) {
      message
    }
  }
`;

export const SAVE_BOX_TYPE = `
  mutation saveBoxType(
    $name: String
    $id: ID
    $customers: [ID!]
    $warehouses: [ID!]
    $length: Float
    $width: Float
    $height: Float
    $formFactor: String
    $contents: [BoxTypeInputItem!]
  ) {
    saveBoxType(
      boxTypeInput: {
        name: $name
        id: $id
        length: $length
        width: $width
        height: $height
        customers: $customers
        warehouses: $warehouses
        formFactor: $formFactor
        contents: $contents
      }
    ) {
      message
    }
  }
`;

export const DELETE_BOX_TYPE = `
  mutation DeleteBoxType($id: ID!) {
    deleteBoxType(id: $id) {
      message
    }
  }
`;

export const SAVE_BIN_LOCATION = `
  mutation saveBinLocation(
    $binLocationInput: BinLocationInput
  ) {
    saveBinLocation(
      binLocationInput: $binLocationInput
    ) {
      message
    }
  }
`;

export const SAVE_BULK_BIN_LOCATIONS = `
  mutation BulkSaveBinLocations(
    $binLocationsBody: Random
  ) {
    bulkSaveBinLocations(
      binLocationsBody: $binLocationsBody
    ) {
      message
    }
  }
`;

export const DELETE_BIN_LOCATION = `
  mutation DeleteBinLocation($id: ID!) {
    deleteBinLocation(id: $id) {
      message
    }
  }
`;

export const SAVE_SORTING_LOCATION = `
  mutation saveSortingLocation(
    $barcode: String!
    $sortingLocationType: String!
    $id: ID
    $position: Int
    $warehouses: [ID!]
    $customers: [ID!]
  ) {
    saveSortingLocation(
      sortingLocationInput: {
        barcode: $barcode
        id: $id
        sortingLocationType: $sortingLocationType
        position: $position
        customers: $customers
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_SORTING_LOCATION = `
  mutation DeleteSortingLocation($id: ID!) {
    deleteSortingLocation(id: $id) {
      message
    }
  }
`;

export const SAVE_RECEIVED_SKU = `
  mutation SaveReceivedSku($id: ID, $quantity: Int) {
    saveReceivedSku(receivedSkuInput: { quantity: $quantity, id: $id }) {
      message
    }
  }
`;

// INVENTORY UPDATE

export const UPDATE_INVENTORY = `
  mutation UpdateInventory(
    $id: ID!
    $stock: Int!
    $user: String!
    $reason: String
    $otherReason: String
  ) {
    updateInventory(
      id: $id
      stock: $stock
      user: $user
      reason: $reason
      otherReason: $otherReason
    ) {
      message
    }
  }
`;

// export const RELEASE_INVENTORY_SHIPMENT_PLAN_CREATION = `
//   mutation ReleaseInventoryForShipmentPlan($id: ID!) {
//     releaseInventoryForShipmentPlan(id: $id) {
//       message
//     }
//   }
// `;
export const RELEASE_INVENTORY_SHIPMENT_PLAN_CREATION = `
  mutation ReleaseInventoryForShipmentPlan(
    $customer: ID
    $products: [Random]
    $typeOfShipment: String
    $intendedBoxContentsSource: String
  ) {
    releaseInventoryForShipmentPlan(
      customer: $customer
      products: $products
      typeOfShipment: $typeOfShipment
      intendedBoxContentsSource: $intendedBoxContentsSource
    ) {
      message
    }
  }
`;

// INWARD RECEIVER

export const SCAN_INWARD_RECEIVER_BARCODE = `
  mutation ScanInwardReceiverBarcode($code: String!, $trackingNumber: [String], $customer: String, $consignmentId: ID) {
    scanInwardReceiverBarcode(code: $code, trackingNumber: $trackingNumber, customer: $customer, consignmentId: $consignmentId) {
      message
    }
  }
`;

export const CHECK_FOR_CONSIGNMENT = `
  mutation InwardReceiverCheckForConsignment($trackingNumber: [String], $customer: String, $consignmentId: ID) {
    checkForConsignment(trackingNumber: $trackingNumber, customer: $customer, consignmentId: $consignmentId) {
      message
      consignments {
        items {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          productId
        }
        workingList {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          productId
        }
        status
        createdAt
        consignmentNumber
        trackingNumber
        orderId
        id
        consignmentDate
        supplier
      }
    }
  }
`;

export const CONFIRM_INWARD_ITEM = `
  mutation ConfirmInwardItem($item: InwardItemInput!, $items: [InwardItemInput!]) {
    confirmInwardItem(item: $item, items: $items) {
      message
    }
  }
`;

export const SUBMIT_NUMBER_OF_CARTONS = `
  mutation SubmitNumberOfCartons($consignmentId: ID, $numberOfCartons: Int, $bestByDate: Date) {
    submitNumberOfCartons(consignmentId: $consignmentId, numberOfCartons: $numberOfCartons, bestByDate: $bestByDate) {
      message
    }
  }
`;

export const ADD_LPN_TO_PRODUCT = `
  mutation addLpnToProduct($lpn: String, $productId: ID) {
    addLpnToProduct(lpn: $lpn, productId: $productId) {
      message
    }
  }
`;

export const GENERATE_LPN = `
  mutation GenerateLpn($formFactor: String, $products: [Random], $quantity: Int, $trackingNumber: [String], $customer: ID) {
    generateLpn(formFactor: $formFactor, products: $products, quantity: $quantity, customer: $customer, trackingNumber: $trackingNumber) {
      message
      generatedCodes
    }
  }
`;

// STANDALONE PUTAWAY

export const SCAN_STANDALONE_PUTAWAY_BARCODE = `
  mutation ScanStandalonePutawayBarcode($code: String!, $customer: String) {
    scanStandalonePutawayBarcode(code: $code, customer: $customer) {
      message
    }
  }
`;

export const CONFIRM_STANDALONE_PUTAWAY_ITEM = `
  mutation ConfirmStandalonePutawayItem($item: StandalonePutawayItemInput!) {
    confirmStandalonePutawayItem(item: $item) {
      message
    }
  }
`;

export const CANCEL_STANDALONE_PUTAWAY_ITEM = `
  mutation CanceltandalonePutawayItem($id: ID!) {
    cancelStandalonePutawayItem(id: $id) {
      message
    }
  }
`;

// CONSIGNMENT

export const SAVE_CONSIGNMENT = `
mutation saveConsignment(
  $trackingNumber: [String]
  $items: [ConsignmentInputItem!]
  $customer: ID
  $warehouse: ID
  $consignmentNumber: String
  $orderId: String
  $id: ID
  $supplier: String
  $carrier: String
  $consignmentDate: Date
  $notes: String
  $isReturnOrder: Boolean
  $linkedOrders: [Random]
) {
  saveConsignment(
    consignmentInput: {
      trackingNumber: $trackingNumber
      items: $items
      customer: $customer
      warehouse: $warehouse
      consignmentNumber: $consignmentNumber
      orderId: $orderId
      id: $id
      supplier: $supplier
      carrier: $carrier
      consignmentDate: $consignmentDate
      notes: $notes
      isReturnOrder: $isReturnOrder
      linkedOrders: $linkedOrders
    }
  ) {
    message
  }
}
`;

export const SAVE_CONSIGNMENT_NOTES = `
  mutation saveConsignment(
    $id: ID
    $workingList: [Random]
  ) {
    saveConsignmentNotes(
      consignmentInput: {
        id: $id
        workingList: $workingList
      }
    ) {
      message
    }
  }
`;

export const DELETE_CONSIGNMENT = `
  mutation DeleteConsignment($id: ID!) {
    deleteConsignment(id: $id) {
      message
    }
  }
`;

// PREPPER

export const SCAN_PREPPER_BARCODE = `
  mutation ScanPrepperBarcode($code: String!) {
    scanPrepperBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_PREP_ITEM = `
  mutation ConfirmPrepItem($id: ID!, $status: String!, $itemId: String, $quantity: Int) {
    confirmPrepItem(id: $id, status: $status, itemId: $itemId, quantity: $quantity) {
      message
    }
  }
`;

export const CANCEL_PREP_ITEM = `
  mutation CancelPrepItem($id: ID!) {
    cancelPrepItem(id: $id) {
      message
    }
  }
`;

export const RESTART_PREP = `
  mutation RestartPrep($id: ID!) {
    restartPrep(id: $id) {
      message
    }
  }
`;

export const CONFIRM_PREPPER_DROPOFF = `
  mutation ConfirmPrepperDropoff($id: ID!) {
    confirmPrepperDropoff(id: $id) {
      message
    }
  }
`;

// CONSIGNMENT

export const SAVE_BUNDLE = `
  mutation SaveBundle(
    $products: [BundleInputProduct!]
    $customer: ID
    $warehouse: ID
    $name: String
    $uniqueIdentifier: String
    $id: ID
  ) {
    saveBundle(
      bundleInput: {
        id: $id,
        products: $products
        customer: $customer
        warehouse: $warehouse
        name: $name
        uniqueIdentifier: $uniqueIdentifier
      }
    ) {
      message
    }
  }
`;

export const DELETE_BUNDLE = `
  mutation deleteBundle($id: ID!) {
    deleteBundle(id: $id) {
      message
    }
  }
`;

// ENTITY TYPES

export const SAVE_ENTITY_TYPE = `
  mutation SaveEntityType($entityTypeInput: EntityTypeInput) {
    saveEntityType(
      entityTypeInput: $entityTypeInput
    ) {
      message
    }
  }
`;

// RULE ENGINE

export const SAVE_CONFIGURATION = `
  mutation saveConfiguration($configurationInput: ConfigurationInput) {
    saveConfiguration(configurationInput: $configurationInput) {
      message
    }
  }
`;

// NOTIFICATIONS
export const STRIPE_PAY = `
  mutation StripePay($name: String, $amount: String) {
    pay(name: $name, amount: $amount) {
      message
      url
    }
  }
`;

export const APPROVE_NOTIFICATION = `
  mutation ApproveNotification($id: ID!, $body: Random, $remarks: String) {
    approveNotification(id: $id, body: $body, remarks: $remarks) {
      message
    }
  }`;

// BILLING

export const SAVE_BILLING_PROFILE = `
  mutation SaveBillingProfile($billingProfileInput: BillingProfileInput!) {
    saveBillingProfile(billingProfileInput: $billingProfileInput) {
      message
    }
  }
`;

export const ASSIGN_BILLING_PROFILE = `
  mutation AssignBillingProfile($customerId: String, $billingProfileId: String) {
    assignBillingProfile(customerId: $customerId, billingProfileId: $billingProfileId) {
      message
    }
  }
`;

export const DELETE_BILLING_PROFILE = `
  mutation DeleteBillingProfile ($id: String) {
    deleteBillingProfile (id: $id) {
      message
    }
  }
`;

export const REJECT_NOTIFICATION = `
  mutation RejectNotification($id: ID!, $remarks: String) {
    rejectNotification(id: $id, remarks: $remarks) {
      message
    }
  }
`;

export const SAVE_INVOICE = `
  mutation SaveInvoice($invoiceInput: InvoiceInput!) {
    saveInvoice (invoiceInput: $invoiceInput) {
      message
    }
  }
`;
// ORDER

export const DELETE_INVOICE = `
  mutation DeleteInvoice($id: String) {
    deleteInvoice(id: $id) {
      message
    }
  }
`;

export const SAVE_ORDER = `
  mutation saveOrder(
    $trackingNumber: String
    $orderLineItems: [OrderLineItemInput]
    $customer: ID
    $warehouse: ID
    $orderId: String
    $id: ID
    $carrier: String
    $orderDate: Date
    $workflowType: String
    $shippingAddress: ShippingAddressInput
  ) {
    saveOrder(
      orderInput: {
        trackingNumber: $trackingNumber
        orderLineItems: $orderLineItems
        customer: $customer
        warehouse: $warehouse
        orderId: $orderId
        id: $id
        carrier: $carrier
        orderDate: $orderDate
        workflowType: $workflowType
        shippingAddress: $shippingAddress
      }
    ) {
      message
    }
  }
`;

export const UPDATE_ORDER_LINE_ITEM = `
  mutation updateOrderLineItem($orderLineItemInput: Random) {
    updateOrderLineItem(orderLineItemInput: $orderLineItemInput) {
      message
    }
  }
`;

// MATERIALS

export const SAVE_MATERIAL = `
  mutation SaveMaterial ($materialInput: MaterialInput) {
    saveMaterial (materialInput: $materialInput) {
      message
    }
  } 
`;

export const DELETE_MATERIAL = `
  mutation DeleteMaterial ($id: ID!) {
    deleteMaterial (id: $id) {
      message
    }
  } 
`;

export const IMMEDIATE_SCAN = `
  mutation ImmediateScan($barcode: String!) {
    immediateScan(barcode: $barcode) {
      barcode
    }
  }
`;

export const CANCEL_RECEIVING_ITEM = `
  mutation CancelReceivingItem($id: ID!) {
    cancelReceivingItem(id: $id) {
      message
    }
  }
`;

// CYCLE COUNT PLANS

export const SAVE_CYCLE_COUNT_PLAN = `
  mutation SaveCycleCountPlan ($cycleCountPlanInput: CycleCountPlanInput) {
    saveCycleCountPlan (cycleCountPlanInput: $cycleCountPlanInput) {
      message
    }
  } 
`;

export const DELETE_CYCLE_COUNT_PLAN = `
  mutation DeleteCycleCountPlan ($id: ID!) {
    deleteCycleCountPlan (id: $id) {
      message
    }
  } 
`;

export const SCAN_CYCLE_COUNTER_BARCODE = `
    mutation ScanCounterBarcode($code: String!) {
    scanCounterBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_CYCLE_COUNT_ITEM = `
  mutation ConfirmCycleCountItem($id: ID!, $item: CycleCounterConfirmItem!) {
    confirmCounterItem(id: $id, item: $item) {
      message
    }
  }
`;

export const EXECUTE_CYCLE_COUNT_PLAN = `
  mutation ExecuteCycleCountPlan($executePlanInput: ExecutePlanInput!) {
    executeCycleCountPlan (executePlanInput: $executePlanInput) {
      message
    }
  }
`;

export const SAVE_INTEGRATION_CREDS = `
  mutation SaveIntegrationCreds(
    $tenant: String
    $customer: String
    $warehouse: String
    $integrationType: String
    $clientId: String
    $clientSecret: String
    $authCode: String
    $redirectUri: String
  ) {
    saveIntegrationCreds(
      tenant: $tenant
      customer: $customer
      integrationType: $integrationType
      clientId: $clientId
      clientSecret: $clientSecret
      authCode: $authCode
      redirectUri: $redirectUri
      warehouse: $warehouse
    ) {
      message
    }
  }
`;
