import { useReducer, createContext } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
const config = require("config");

const initialState = {
  loading: false,
  alerts: [],
  pageTitle: "Hopstack Inc",
  confirmation: {
    open: false,
    mainText: "Are you sure",
    subText: "This action cannot be undone",
    negative: () => {},
    positive: () => {},
  },
  defaultTimezone: "EST",
  displayFields: [],
  enabledSettings: false,
  subdomain: null,
  tenant: null,
};

const AppStateContext = createContext({});

function appStateReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "REMOVE_LOADING": {
      return {
        ...state,
        loading: false,
      };
    }
    case "SET_ALERT":
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case "REMOVE_ALERT":
      return {
        ...state,
        alerts: _.filter(state.alerts, (alert) => alert.id !== payload),
      };
    case "SET_ACTIONABLE_ALERT":
      return {
        ...state,
        actionableAlert: payload,
      };
    case "REMOVE_ACTIONABLE_ALERT":
      return {
        ...state,
        actionableAlert: null,
      };
    case "SHOW_CONFIRMATION": {
      return {
        ...state,
        confirmation: payload,
      };
    }
    case "HIDE_CONFIRMATION": {
      return {
        ...state,
        confirmation: initialState.confirmation,
      };
    }
    case "SET_SUBDOMAIN": {
      return {
        ...state,
        subdomain: payload,
      };
    }
    case "SET_TENANT": {
      return {
        ...state,
        tenant: payload,
      };
    }
    default:
      return state;
  }
}

function AppStateProvider(props) {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  function setLoading() {
    dispatch({
      type: "SET_LOADING",
    });
  }

  function removeLoading() {
    dispatch({
      type: "REMOVE_LOADING",
    });
  }

  function setAlert(msg, alertType, timeout = 5000) {
    const id = uuidv4();
    dispatch({
      type: "SET_ALERT",
      payload: {
        msg,
        alertType,
        id,
      },
    });

    setTimeout(() => dispatch({ type: "REMOVE_ALERT", payload: id }), timeout);
  }

  function setActionableAlert(title, description, mainAction) {
    dispatch({
      type: "SET_ACTIONABLE_ALERT",
      payload: {
        title,
        description,
        mainAction,
      },
    });
  }

  function removeActionableAlert() {
    dispatch({ type: "REMOVE_ACTIONABLE_ALERT" });
  }

  function showConfirmation(mainText, subText, positive, negative) {
    const payload = {
      open: true,
      mainText,
      subText,
      negative,
      positive,
    };

    dispatch({ type: "SHOW_CONFIRMATION", payload });
  }

  function hideConfirmation() {
    dispatch({ type: "HIDE_CONFIRMATION" });
  }

  function setSubdomain(subdomain) {
    localStorage.setItem("subdomain", subdomain);
    dispatch({ type: "SET_SUBDOMAIN", payload: subdomain });
  }

  function setTenant(payload) {
    localStorage.setItem("tenant", JSON.stringify(payload));
    console.log(payload);
    if (payload.apiGateway) {
      localStorage.setItem("baseUrl", payload.apiGateway);
    } else if (process.env.NODE_ENV === "development") {
      localStorage.setItem("baseUrl", config.DEFAULT_API_GATEWAY);
    }
    if (payload.socketService) {
      localStorage.setItem("socketService", payload.socketService);
    } else if (process.env.NODE_ENV === "development") {
      localStorage.setItem("socketService", config.DEFAULT_SOCKET_SERVICE);
    }
    if (payload.cubeService) {
      localStorage.setItem("cubeService", payload.cubeService);
    } else if (process.env.NODE_ENV === "development") {
      localStorage.setItem("cubeService", config.DEFAULT_CUBE_SERVICE);
    }
    dispatch({ type: "SET_TENANT", payload });
  }

  return (
    <AppStateContext.Provider
      value={{
        ...state,
        setLoading,
        removeLoading,
        setAlert,
        showConfirmation,
        hideConfirmation,
        setSubdomain,
        setTenant,
        setActionableAlert,
        removeActionableAlert,
      }}
      {...props}
    />
  );
}

export { AppStateContext, AppStateProvider };
