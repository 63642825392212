const TextField = ({
  type,
  id,
  placeholder,
  label,
  onChange,
  value,
  rounded,
  className,
  autoFocus = false,
  ...rest
}) => {
  if (rounded) {
    return (
      <input
        className="border-2 border-2C7695  rounded-full block p-2 w-full text-lg appearance-none focus:outline-none focus:ring-0 font-montserrat"
        id={id}
        onChange={onChange}
        required={false}
        value={value}
        type={type}
        name={id}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...rest}
      />
    );
  }
  return (
    <div className="outline relative border-2 border-2C7695 rounded-lg overflow-visible w-full">
      <input
        type={type}
        name={id}
        placeholder={placeholder}
        className="block p-4 w-full text-lg appearance-none focus:outline-none focus:ring-0 border-0 focus:border-0 bg-transparent font-montserrat"
        id={id}
        onChange={onChange}
        required={false}
        value={value}
        autoFocus={autoFocus}
        {...rest}
      />
      <label
        htmlFor={id}
        className={`absolute top-0 bg-white text-lg p-2 m-2 -z-1 duration-300 origin-0 font-montserrat font-medium ml-2 text-2C7695 ${className}`}
      >
        {label}
      </label>
    </div>
  );
};

export default TextField;
