const LoginBox = ({ subdomain }) => (
  <div className="bg-white xs:10/12 sm:w-8/12 md:6/12 max-w-xl min-w-xl rounded-2xl p-10 flex flex-col space-y-6 items-center justify-center">
    <div className="text-454A4F text-2xl font-medium">
      Welcome to Hopstack Inc.
    </div>
    <div className="text-454A4F text-2xl font-medium">
      You have successfully logged in.
    </div>
    <div
      onClick={() => {
        if (subdomain) {
          window.location = `https://${subdomain}.hopstack.io/integrations`;
        }
      }}
      className="cursor-pointer inline-flex justify-center p-4 text-2xl font-normal text-C2D2DF bg-224E73 font-montserrat border border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none w-64"
    >
      Continue
    </div>
  </div>
);

export default LoginBox;
