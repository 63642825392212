const config = require("config");
import { useState } from "react";
import axios from "axios";

const initialState = {
  loading: false,
  data: null,
  error: null,
  variables: {},
};

export const useQuery = (query, baseUrl) => {
  const [state, setState] = useState(initialState);

  const fetchData = async (variables = {}) => {
    // let { baseUrl } = localStorage;
    if (!baseUrl) {
      return {
        ...state,
      };
    }
    setState({
      ...initialState,
      loading: true,
    });
    try {
      const response = await axios.post(
        `${baseUrl}/graphql`,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data.errors) {
        throw response.data.errors[0];
      }
      setState({
        data: response.data.data,
        error: null,
        loading: false,
        variables,
      });
    } catch (err) {
      setState({
        data: null,
        error: err,
        loading: false,
        variables,
      });
    }
  };

  return {
    ...state,
    fetchData,
  };
};

axios.interceptors.request.use(
  function (config) {
    // const { token, subdomain, tenant } = localStorage;
    // if (!!token) config.headers["authorization"] = `Bearer ${token}`;
    // if (!!!subdomain) throw new Error(`Access denied`);
    // config.headers["subdomain"] = subdomain;
    // config.headers["tenant"] = tenant;
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
