const HeaderWithoutSidebar = () => {
  return (
    <div className="w-full flex justify-center absolute top-0 left-0">
      {/*Logo*/}
      <div className="flex items-center justify-center space-x-4 py-2 px-2">
        <img
          src="https://hopstack-pub.s3.amazonaws.com/logo.png"
          alt="Picture of the author"
          className="w-12 h-12"
        />
        <span className="font-hammersmith text-white text-2xl">
          Hopstack Inc
        </span>
      </div>
      {/*. User Details */}
    </div>
  );
};

export default HeaderWithoutSidebar;
