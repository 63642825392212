import { Route, Switch } from "react-router-dom";

import PrivateRoute from "#config/PrivateRoute";

import NotFound from "#pages/404";

import OAuth from "#pages/oauth";

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={OAuth} />
      <Route exact path="/:marketplace/:action" component={OAuth} />
      <Route exact path="/:marketplace" component={OAuth} />
      <Route component={NotFound} />
    </Switch>
  </>
);

export default Routes;
