import React from "react";
import { render } from "react-dom";
import { createBrowserHistory } from "history";
import "regenerator-runtime/runtime.js";
import App from "#pages/App";

import { AuthProvider } from "#contexts/auth";
import { AppStateProvider } from "#contexts/appState";
import { MasterDataProvider } from "#contexts/masterData";
import { NotificationProvider } from "#contexts/notification";
const robots = require('../public/robots.txt');

const history = createBrowserHistory();

render(
  <AuthProvider>
    <AppStateProvider>
      <MasterDataProvider>
        <NotificationProvider>
          <App history={history} />
        </NotificationProvider>
      </MasterDataProvider>
    </AppStateProvider>
  </AuthProvider>,
  document.getElementById("root")
);
