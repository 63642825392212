import { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import {
  BellIcon,
  GlobeAltIcon,
  MenuAlt2Icon,
  XIcon,
  CogIcon,
} from "@heroicons/react/outline";
import {
  UserCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { NotificationContext } from "#contexts/notification";
import { GET_USER, GET_NOTIFICATIONS } from "#queries";
import { LOGOUT_USER, SAVE_USER } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import UserPreferenceForm from "./UserPreferenceForm";

// Images

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    isCurrent: (pathname) => pathname === "/dashboard",
    selectedImage: "warehouse_selected.png",
    unselectedImage: "warehouse_unselected.png",
  },
  // {
  //   name: "Notifications",
  //   href: "/notifications",
  //   isCurrent: (pathname) => pathname === "/notifications",
  //   selectedImage: "integrations_selected.png",
  //   unselectedImage: "integrations_unselected.png",
  // },
  {
    name: "Outbound Logistics",
    selectedImage: "warehouse_selected.png",
    unselectedImage: "warehouse_unselected.png",
    isCurrent: (pathname) =>
      ["/orders", "/outbound", "/outboundExceptions"].includes(pathname),
    children: [
      {
        name: "Order management",
        href: "/orders",
        selectedImage: "orders_selected.png",
        unselectedImage: "orders_unselected.png",
        isCurrent: (pathname) => pathname === "/orders",
      },
      {
        name: "Exceptions",
        href: "/outboundExceptions",
        selectedImage: "orders_selected.png",
        unselectedImage: "orders_unselected.png",
        isCurrent: (pathname) => pathname === "/outboundExceptions",
      },
      {
        name: "Operations",
        href: "/outbound",
        selectedImage: "orders_selected.png",
        unselectedImage: "orders_unselected.png",
        isCurrent: (pathname) => pathname === "/outbound",
      },
    ],
  },
  {
    name: "Inbound Logistics",
    selectedImage: "warehouse_selected.png",
    unselectedImage: "warehouse_unselected.png",
    isCurrent: (pathname) =>
      ["/consignments", "/receivedSkus", "/inbound"].includes(pathname),
    children: [
      {
        name: "Consignments",
        href: "/consignments",
        selectedImage: "orders_selected.png",
        unselectedImage: "orders_unselected.png",
        isCurrent: (pathname) => pathname === "/consignments",
      },
      // {
      //   name: "Received SKUs",
      //   href: "/receivedSkus",
      //   selectedImage: "orders_selected.png",
      //   unselectedImage: "orders_unselected.png",
      //   isCurrent: (pathname) => pathname === "/receivedSkus",
      // },
      {
        name: "Operations",
        href: "/inbound",
        selectedImage: "orders_selected.png",
        unselectedImage: "orders_unselected.png",
        isCurrent: (pathname) => pathname === "/inbound",
      },
    ],
  },
  {
    name: "Inventory",
    selectedImage: "integrations_selected.png",
    unselectedImage: "integrations_unselected.png",
    isCurrent: (pathname) =>
      [
        "/inventoryOverview",
        "/inventoryMeasurement",
        "/forecasts",
        "/products",
        "/cycle-count-plans",
      ].includes(pathname),
    children: [
      {
        name: "Product Management",
        href: "/products",
        isCurrent: (pathname) => pathname === "/products",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
      {
        name: "Measurement",
        href: "/inventoryMeasurement",
        isCurrent: (pathname) => pathname === "/inventoryMeasurement",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
      {
        name: "Stock Cycle Count",
        href: "/cycle-count-plans",
        selectedImage: "users_selected.png",
        unselectedImage: "users_unselected.png",
        isCurrent: (pathname) => pathname === "/cycle-count-plans",
      },
      // {
      //   name: "Forecast",
      //   href: "/forecasts",
      //   current: history.pathname === "/forecasts",
      //   selectedImage: "integrations_selected.png",
      //   unselectedImage: "integrations_unselected.png",
      // },
      // {
      //   name: "Operations",
      //   href: "/inventoryOverview",
      //   isCurrent: (pathname) => pathname === "/inventoryOverview",
      //   selectedImage: "integrations_selected.png",
      //   unselectedImage: "integrations_unselected.png",
      // },
    ],
  },
  {
    name: "Billing",
    selectedImage: "integrations_selected.png",
    unselectedImage: "integrations_unselected.png",
    isCurrent: (pathname) => ["/usage", "/invoicing"].includes(pathname),
    children: [
      {
        name: "Usage",
        href: "/usage",
        isCurrent: (pathname) => pathname === "/usage",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
      {
        name: "Invoicing",
        href: "/invoicing",
        isCurrent: (pathname) => pathname === "/invoicing",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
    ],
  },
];
const setupNavigation = {
  name: "Setup",
  selectedImage: "stations_selected.png",
  unselectedImage: "stations_unselected.png",
  isCurrent: (pathname) =>
    [
      "/users",
      "/devices",
      "/stations",
      "/workflow",
      "/rules",
      "/integrations",
      "/rule",
      "/group",
      "/skuBinMappings",
      "/warehouses",
      "/customers",
      "/bundles",
      "/totes",
      "/boxTypes",
      "/locations",
      "/skuBoxMappings",
      "/materials",
    ].includes(pathname),
  children: [
    // {
    //   name: "Customer Management",
    //   href: "/customers",
    //   selectedImage: "users_selected.png",
    //   unselectedImage: "users_unselected.png",
    //   current: history.pathname === "/customers",
    // },
    {
      name: "Warehouse Management",
      href: "/warehouses",
      selectedImage: "users_selected.png",
      unselectedImage: "users_unselected.png",
      isCurrent: (pathname) => pathname === "/warehouses",
    },
    {
      name: "Packing Materials",
      href: "/materials",
      selectedImage: "users_selected.png",
      unselectedImage: "users_unselected.png",
      isCurrent: (pathname) => pathname === "/materials",
    },
    {
      name: "Billing Profiles",
      href: "/billing-profiles",
      isCurrent: (pathname) => pathname === "/billing-profiles",
      selectedImage: "integrations_selected.png",
      unselectedImage: "integrations_unselected.png",
    },
    {
      name: "Tenant Management",
      href: "/customers",
      selectedImage: "users_selected.png",
      unselectedImage: "users_unselected.png",
      isCurrent: (pathname) => pathname === "/customers",
    },
    {
      name: "User Management",
      href: "/users",
      selectedImage: "users_selected.png",
      unselectedImage: "users_unselected.png",
      isCurrent: (pathname) => pathname === "/users",
    },
    // {
    //   name: "Glossary",
    //   href: "/glossary",
    //   selectedImage: "users_selected.png",
    //   unselectedImage: "users_unselected.png",
    //   current: history.pathname === "/glossary",
    // },
    // {
    //   name: "Layout Management",
    //   selectedImage: "users_selected.png",
    //   unselectedImage: "users_unselected.png",
    //   current: history.pathname === "/layoutManagement",
    //   href: "/layoutManagement",
    // },
    {
      name: "Integration Management",
      href: "/integrations",
      selectedImage: "integrations_selected.png",
      unselectedImage: "integrations_unselected.png",
      isCurrent: (pathname) => pathname === "/integrations",
    },
    // {
    //   name: "Device Management",
    //   href: "/devices",
    //   selectedImage: "devices_selected.png",
    //   unselectedImage: "devices_unselected.png",
    //   isCurrent: (pathname) => pathname === "/devices",
    // },
    // {
    //   name: "Workflow Management",
    //   href: "/workflows",
    //   selectedImage: "workflow_selected.png",
    //   unselectedImage: "workflow_unselected.png",
    //   current: history.pathname === "/workflows",
    // },
    {
      name: "Workflow Management",
      href: "/workflows",
      selectedImage: "workflow_selected.png",
      unselectedImage: "workflow_unselected.png",
      isCurrent: (pathname) => pathname === "/workflows",
    },
    {
      name: "Station Management",
      href: "/stations",
      selectedImage: "stations_selected.png",
      unselectedImage: "stations_unselected.png",
      isCurrent: (pathname) => pathname === "/stations",
    },
    // {
    //   name: "Rule Engine",
    //   href: "/rules",
    //   selectedImage: "rules_selected.png",
    //   unselectedImage: "rules_unselected.png",
    //   current: ["/rules", "/rule", "/group"].includes(history.pathname),
    // },
    {
      name: "Tote Management",
      href: "/totes",
      selectedImage: "devices_selected.png",
      unselectedImage: "devices_unselected.png",
      isCurrent: (pathname) => pathname === "/totes",
    },
    {
      name: "Box/Pallet Management",
      href: "/boxTypes",
      selectedImage: "devices_selected.png",
      unselectedImage: "devices_unselected.png",
      isCurrent: (pathname) => pathname === "/boxTypes",
    },
    {
      name: "Location Management",
      href: "/locations",
      selectedImage: "devices_selected.png",
      unselectedImage: "devices_unselected.png",
      isCurrent: (pathname) => pathname === "/locations",
    },
    {
      name: "SKU-Bin Management",
      href: "/skuBinMappings",
      selectedImage: "rules_selected.png",
      unselectedImage: "rules_unselected.png",
      isCurrent: (pathname) => pathname === "/skuBinMappings",
    },
    // {
    //   name: "SKU-Box Management",
    //   href: "/skuBoxMappings",
    //   selectedImage: "rules_selected.png",
    //   unselectedImage: "rules_unselected.png",
    //   isCurrent: (pathname) => pathname === "/skuBoxMappings",
    // },
    {
      name: "Bundle Management",
      href: "/bundles",
      selectedImage: "integrations_selected.png",
      unselectedImage: "integrations_unselected.png",
      isCurrent: (pathname) => pathname === "/bundles",
    },
    // {
    //   name: "Product Management",
    //   href: "/products",
    //   selectedImage: "rules_selected.png",
    //   unselectedImage: "rules_unselected.png",
    //   current: ["/products"].includes(history.pathname),
    // },
    // {
    //   name: "Settings",
    //   href: "/settings",
    //   selectedImage: "rules_selected.png",
    //   unselectedImage: "rules_unselected.png",
    //   current: history.pathname === "/settings",
    // },
    {
      name: "Rule Engine",
      href: "/rules",
      selectedImage: "rules_selected.png",
      unselectedImage: "rules_unselected.png",
      isCurrent: (pathname) => ["/rules", "/rule", "/group"].includes(pathname),
    },
  ],
};

export const NavigationRoutes = [...navigation, setupNavigation];

const AdminLayout = ({ children }) => {
  let history = useHistory();
  const notificationsQuery = useQuery(GET_NOTIFICATIONS);
  const logoutQuery = useQuery(LOGOUT_USER);
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const notification = useContext(NotificationContext);
  const saveUserQuery = useQuery(SAVE_USER);
  const getUserQuery = useQuery(GET_USER);
  const [currentUser, setCurrentUser] = useState(null);
  const [navigationRoutes, setNavigationRoutes] = useState([
    ...NavigationRoutes,
  ]);
  const [preferenceFormVisible, setPreferenceFormVisible] = useState(false);
  const [preferredNavigationRoutes, setPreferredNavigationRoutes] = useState([
    ...NavigationRoutes,
  ]);

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(logoutQuery.data.logout.message);
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  useEffect(() => {
    if (notificationsQuery.data && notificationsQuery.data.notifications) {
      notification.setEntities({
        ...notificationsQuery.data.notifications,
        ...notificationsQuery.variables,
      });
      if (notificationsQuery.data.notifications.unreadTotal > 0) {
        appState.setActionableAlert(
          `Hello ${auth.user.name}`,
          `You have ${notificationsQuery.data.notifications.unreadTotal} unread notification(s).`,
          () => history.push("/notifications")
        );
      }
    }
  }, [
    notificationsQuery.data,
    notificationsQuery.error,
    notificationsQuery.loading,
  ]);

  useEffect(() => {
    if (
      auth &&
      auth.user &&
      auth.user.role &&
      auth.user.role.toLowerCase() === "admin"
    ) {
      notificationsQuery.fetchData({
        perPage: 25,
        pageNumber: 1,
        sort: "-status",
      });
    }
  }, []);

  useEffect(() => {
    if (saveUserQuery.data) {
      appState.setAlert(saveUserQuery.data.saveUser.message);
      setPreferenceFormVisible(false);
    }

    if (saveUserQuery.error) {
      appState.setAlert(saveUserQuery.error.message, "error", 5000);
    }
  }, [saveUserQuery.data, saveUserQuery.loading, saveUserQuery.error]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    let allowedNavigation = [...NavigationRoutes];
    if (
      auth &&
      !currentUser?.permissions?.length &&
      !currentUser?.pagePreferences?.length
    ) {
      const defaultRoutes = makeDefaultPagePreference();
      setPreferredNavigationRoutes(defaultRoutes);
      setNavigationRoutes(defaultRoutes);
      return;
    }

    if (auth && currentUser?.permissions?.length) {
      allowedNavigation = allowedNavigation
        .map(parseUserPermissions(auth.user.permissions))
        .filter((item) => item && item.name);
      setNavigationRoutes(allowedNavigation);
    }
    console.log(currentUser.pagePreferences, "prefer");
    if (currentUser?.pagePreferences?.length) {
      // Add 'visible' field in naviagtion routes
      allowedNavigation = allowedNavigation.map((item) => {
        if (item.href && !item.children?.length) {
          // Dashboard route
          const currentVisible = currentUser.pagePreferences.find(
            (preferredItem) => preferredItem.route === item.href
          )?.visible;
          return {
            ...item,
            visible: currentVisible === undefined ? true : currentVisible,
          };
        }
        if (item.children.length) {
          return {
            ...item,
            children: item.children.map((child) => {
              const currentVisible = currentUser.pagePreferences.find(
                (preferredItem) => preferredItem.route === child.href
              )?.visible;
              return {
                ...child,
                visible: currentVisible === undefined ? true : currentVisible,
              };
            }),
          };
        }
        return item;
      });

      // Filter out hidden routes
      setPreferredNavigationRoutes([...allowedNavigation]);
    } else {
      setPreferredNavigationRoutes(
        makeDefaultPagePreference(currentUser?.permissions)
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (auth.user.id) getUserQuery.fetchData({ id: auth.user.id });
  }, []);

  useEffect(() => {
    if (getUserQuery.data) {
      setCurrentUser(getUserQuery.data.user);
    }
  }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

  const onChangePagePreference = (route, visible) => {
    setPreferredNavigationRoutes(
      preferredNavigationRoutes.map((item) => {
        if (!route.href && route.name === item.name) {
          return {
            ...item,
            children: [...item.children].map((child) => ({
              ...child,
              visible,
            })),
          };
        }
        if (item.children?.length) {
          return {
            ...item,
            children: item.children.map((child) => {
              if (child.href === route.href) {
                return { ...child, visible };
              }
              return child;
            }),
          };
        }
        if (item.href === route.href) {
          return { ...item, visible };
        }
        return item;
      })
    );
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <LeftSidebarInner navigation={navigationRoutes} />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0 z-10">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftSidebarInner
            navigation={preferredNavigationRoutes
              .map((item) => {
                if (!item.children?.length && !item.visible) {
                  return null;
                }
                if (item.children?.length) {
                  const children = item.children.filter(
                    (child) => child.visible
                  );
                  if (children.length) {
                    return { ...item, children };
                  }
                  return null;
                }
                return item;
              })
              .filter((item) => item && item.name)}
            setPreferenceFormVisible={setPreferenceFormVisible}
          />
          {preferenceFormVisible && (
            <UserPreferenceForm
              title="Select pages you want to see"
              onClose={() => setPreferenceFormVisible(false)}
              onSubmit={() =>
                saveUserQuery.fetchData({
                  ...currentUser,
                  pagePreferences: preferredNavigationRoutes
                    .reduce((prev, curr) => {
                      if (curr.children?.length) {
                        return [...prev, ...curr.children, curr];
                      }
                      return [...prev, curr];
                    }, [])
                    .filter((item) => item.href?.length)
                    .map(
                      (item) =>
                        item.href && {
                          route: item.href,
                          visible: item.visible,
                        }
                    )
                    .filter((item) => item?.visible !== undefined),
                })
              }
              onChangePreference={onChangePagePreference}
              options={preferredNavigationRoutes}
            />
          )}
        </div>
        <div className="md:pl-72 flex flex-col">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex"></div>
              <div className="ml-4 flex items-center md:ml-6">
                {auth?.user?.role?.toLowerCase() === "admin" && (
                  <Link to="/notifications">
                    <div className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer">
                      <span className="sr-only">Open user menu</span>
                      <BellIcon className="h-8 w-8 rounded-full" alt="" />
                      {notification.unreadTotal &&
                      notification.unreadTotal > 0 ? (
                        <div className="inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-red-800 text-white -mt-4 -ml-4">
                          {notification.unreadTotal}
                        </div>
                      ) : null}
                    </div>
                  </Link>
                )}

                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <GlobeAltIcon className="h-8 w-8" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            English(en)
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Spanish(es)
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Simplified Chinese(zh)
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-8 w-8 rounded-full" alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              logoutQuery.fetchData();
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Logout
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 min-h-screen relative overflow-y-auto focus:outline-none bg-EBEBEB">
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

const Logo = () => (
  <div className="flex items-center justify-center space-x-2 py-2">
    <img
      src="https://hopstack-pub.s3.amazonaws.com/logo.png"
      alt="Picture of the author"
      className="w-10 h-10"
    />
    <span className="font-hammersmith text-white text-xl">Hopstack Inc</span>
  </div>
);

const LeftSidebarInner = ({ navigation, setPreferenceFormVisible }) => {
  const history = useHistory();
  return (
    <div className="flex-1 flex flex-col min-h-0 bg-224E73 whitespace-nowrap">
      <Logo />
      <span className="w-11/12 m-auto flex justify-end">
        <button onClick={setPreferenceFormVisible}>
          <CogIcon className="h-6 w-6 text-white" />
        </button>
      </span>
      <div className="flex-1 flex flex-col overflow-y-auto">
        <nav className="flex-1 px-2 py-4 space-y-4">
          {navigation.map((item) =>
            !item.children ? (
              <TopLevelItem
                item={{
                  ...item,
                  current: item.isCurrent(history.location.pathname),
                }}
                key={item.name}
              />
            ) : (
              <InnerMostDisclosure
                item={{
                  ...item,
                  current: item.isCurrent(history.location.pathname),
                  children: item.children.map((child) => ({
                    ...child,
                    current: child.isCurrent(history.location.pathname),
                  })),
                }}
                key={item.name}
              />
            )
          )}
        </nav>
      </div>
    </div>
  );
};

const TopLevelItem = ({ item }) => (
  <div>
    <Link to={item.href ? item.href : item.name}>
      <div
        className={`ml-1 flex w-full items-center rounded-full rounded-tr-none rounded-br-none border-0 border-transparent p-0 ring-0 outline-0 ${
          item.current ? "text-1D3752 bg-EBEBEB" : "text-C2D2DF bg-1D3752"
        }`}
      >
        <RenderIcon item={item} />
      </div>
    </Link>
  </div>
);

const NestedItem = ({ item }) =>
  item.current ? (
    <div className="flex w-full items-center rounded-full ml-8 border-0 border-transparent p-0 ring-0 outline-0 text-1D3752 bg-EBEBEB">
      {" "}
      <RenderIcon item={item} />
    </div>
  ) : (
    <div className="flex w-full items-center rounded-full ml-8 border-0 border-transparent p-0 ring-0 outline-0 text-C2D2DF bg-1D3752 cursor-pointer">
      <Link to={item.href ? item.href : item.name}>
        <RenderIcon item={item} current={item.current} />
      </Link>
    </div>
  );

const OuterDisclosure = ({ item }) => (
  <Disclosure as="div" defaultOpen={item.current}>
    {({ open }) => (
      <>
        <Disclosure.Button
          as="div"
          className={`rounded-full mx-4 border-2 border-F4C261 p-3.5 text-EAF2F8 flex justify-between items-center ${
            item.current && "bg-2C7695"
          }`}
        >
          {item.name}
          {open ? (
            <ChevronUpIcon className="w-7 h-7" />
          ) : (
            <ChevronDownIcon className="w-7 h-7" />
          )}
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500 space-y-4 mt-2">
          {item.children.map((inner, idx) =>
            inner.children ? (
              <InnerMostDisclosure item={inner} key={idx} />
            ) : (
              <NestedItem item={inner} key={idx} />
            )
          )}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

const InnerMostDisclosure = ({ item }) => (
  <Disclosure as="div" defaultOpen={item.current}>
    <div className="bg-1D3752 ml-1 rounded-3xl w-full">
      <Disclosure.Button
        as="div"
        className={`flex w-full items-center rounded-full rounded-tr-none rounded-br-none border-0 border-transparent p-0 ring-0 outline-0 ${
          item.current ? "text-1D3752 bg-EBEBEB" : "text-C2D2DF bg-1D3752"
        }`}
      >
        <RenderIcon item={item} />
      </Disclosure.Button>
      <Disclosure.Panel className="text-gray-500 space-y-2 mt-2">
        {item.children.map((inner, idx) => (
          <Link to={inner.href} key={idx}>
            <div
              className={`px-4 flex w-full items-center ml-12 p-0 py-3 pt-2 ring-0 outline-0 text-C2D2DF bg-1D3752 cursor-pointer ${
                idx != item.children.length - 1 ? "border-b" : "pb-4"
              } border-2C7695 ${inner.current && "text-F4C261"}`}
            >
              {inner.name}
            </div>
          </Link>
        ))}
      </Disclosure.Panel>
    </div>
  </Disclosure>
);

const RenderIcon = ({ item }) =>
  item.href ? (
    <div className="cursor-pointer flex items-center">
      {/* <UsersIcon
          className={`${
            item.current
              ? "font-light w-12 h-12 py-2.5 border border-F4C261 rounded-full bg-F4C261"
              : "font-light w-12 h-12 py-2.5 border border-C2D2DF rounded-full bg-1D3752"
          }`}
          alt=""
        /> */}
      {iconInner(item)}
    </div>
  ) : (
    <div className="cursor-pointer flex items-center">{iconInner(item)}</div>
  );

const iconInner = (item) =>
  item.current ? (
    <>
      <div className="font-light w-12 h-12 flex items-center justify-center py-2.5 border border-F4C261 rounded-full bg-F4C261">
        <img
          src={`https://hopstack-pub.s3.amazonaws.com/icons/${item.selectedImage}`}
          className="w-8"
        />
      </div>
      <span className="px-4">{item.name}</span>
    </>
  ) : (
    <>
      <div className="font-light w-12 h-12 flex items-center justify-center py-2.5 border border-C2D2DF rounded-full bg-1D3752">
        <img
          src={`https://hopstack-pub.s3.amazonaws.com/icons/${item.unselectedImage}`}
          className="w-7"
        />
      </div>
      <span className="px-4">{item.name}</span>
    </>
  );

export default AdminLayout;

// item.current ? (
//   <>
//     <UsersIcon
//       className=
//       alt=""
//     />
//     <span className="px-4">{item.name}</span>
//   </>
// ) : (
//   <Link to={item.href ? item.href : item.name}>
//     <div className="cursor-pointer flex items-center">
//       <UsersIcon
//         className="font-light w-12 h-12 py-2.5 border border-C2D2DF rounded-full bg-1D3752"
//         alt=""
//       />
//       <span className="px-4">{item.name}</span>
//     </div>
//   </Link>
// );

const makeDefaultPagePreference = (userPermission) =>
  [...NavigationRoutes].map((route) => {
    if (route.children?.length) {
      return {
        ...route,
        children: route.children
          .map((child) => {
            let foundRoute = userPermission?.find(
              (pagePermission) => pagePermission.route === child.href
            );

            if (!foundRoute) return null;
            else foundRoute = foundRoute.readable;
            return {
              ...child,
              visible: userPermission?.length ? foundRoute : true,
            };
          })
          .filter((item) => item !== null),
      };
    }
    return { ...route, visible: true };
  });

const parseUserPermissions = (permissions) => (item) => {
  let allowedSubRoutes = item.children || [];
  if (item.children?.length) {
    allowedSubRoutes = item.children.filter((child) => {
      if (!permissions) return item.children;
      const allowedChild = permissions?.find((el) => el.route === child.href);

      // if not found in permissions array then a new route must have been added
      // in this case return default access (true/false)
      if (!allowedChild) return false;
      return allowedChild && allowedChild.readable;
    });
  }
  if (item.href) {
    const route = permissions?.find((el) => el.route === item.href);
    if (route && route.readable) {
      return item;
    }
  }
  if (!allowedSubRoutes.length) {
    return null;
  }
  return { ...item, children: allowedSubRoutes };
};
