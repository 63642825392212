import { useContext } from "react";

import Notification from "#components/utils/Notification";
import ActionableNotification from "#components/utils/ActionableNotification";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import ConfirmationDialog from "#components/utils/ConfirmationDialog";
import { AppStateContext } from "#contexts/appState";

const Layout = ({ children }) => {
  const appState = useContext(AppStateContext);
  return (
    <div className="h-full">
      {appState.loading === true && <LoadingIndicator />}
      <div>
        <div>{children}</div>
      </div>

      <Notification alerts={appState.alerts} />
      {appState.actionableAlert && (
        <ActionableNotification
          text1={appState.actionableAlert.title}
          text2={appState.actionableAlert.description}
          mainAction={appState.actionableAlert.mainAction}
          dismissAction={appState.removeActionableAlert}
        />
      )}
      <ConfirmationDialog confirmation={appState.confirmation} />
    </div>
  );
};

export default Layout;
